.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--sky);
  width: 80%;
}

.page-container {
  display: flex;
  justify-content: center;
}

.content img {
  height: auto;
  width: 100%;
  max-width: 800px;
}
