.nav {
  z-index: 10;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  margin: 0;
  background-color: var(--sky);
  list-style-type: none;
  overflow: hidden;
}

.nav li {
  float: right;
  opacity: 0;
  text-align: center;
}

.nav a {
  font-size: calc(5px + 1vw);
  display: block;
  min-width: 10vw;
  padding: calc(5px + 1vw);
  text-decoration: none;
  font-weight: 600;
  color: var(--ocean);
}

.nav li:nth-child(1) {
  animation: 1s ease-out 1s 1 slideInFromSky forwards;
}
.nav li:nth-child(2) {
  animation: 1s ease-out 1.1s 1 slideInFromSky forwards;
}
.nav li:nth-child(3) {
  animation: 1s ease-out 1.2s 1 slideInFromSky forwards;
}
.nav li:nth-child(4) {
  animation: 1s ease-out 1.3s 1 slideInFromSky forwards;
}
.nav li:nth-child(5) {
  animation: 1s ease-out 1.4s 1 slideInFromSky forwards;
  float: left;
}

.nav a:hover {
  background-color: var(--ocean);
  color: var(--sky);
}

.nav p {
  margin: 0;
}

.nav a i {
  display: none;
}

.nav a:hover i {
  display: block;
  padding: 0px 2vw 0px 2vw;
  animation: slideInFromSky 0.5s;
}

.nav a:hover b {
  display: none;
}

.menu {
  display: none;
}

@media only screen and (max-width: 961px) {
  .menu {
    /* display: inline;
    z-index: 10;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    margin: 0;
    background-color: var(--sky);
    list-style-type: none;
    overflow: hidden; */
  }

  .burger {
    float: right;
  }

  .nav {
    display: none;
  }

  .menu button:focus + .nav,
  .nav:hover {
    .nav {
      display: flex;
      flex-direction: column;
    }
  }
}

/* .home:hover i {
  display: block;
  padding: 0px 2vw 0px 2vw;
  animation: slideInFromSky 0.5s;
}

.about:hover i {
  display: block;
  padding: 0px 2vw 0px 2vw;
  animation: slideInFromSky 0.5s;
}

.proj:hover i {
  display: block;
  padding: 0px 2svw 0px 2svw;
  animation: slideInFromSky 0.5s;
}

.contact:hover i {
  display: block;
  padding: 0px 2svw 0px 2svw;
  animation: slideInFromSky 0.5s;
}

.cv:hover i {
  display: block;
  padding: 0px 2svw 0px 2svw;
  animation: slideInFromSky 0.5s;
} */
