.projects {
  min-height: 90vh;
  background-color: var(--ocean);
  padding-top: 10vh;
  padding-left: 10vw;
  padding-right: 10vw;
  color: var(--sky);
}

.project-table {
  padding: 2vh 0 2vh 0;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 2px solid var(--texthover); */
}

@media only screen and (max-width: 1281px) {
  .project-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
  .col2 img {
    padding: 2vh, 0, 0, 0;
    max-width: 100%;
  }
}

.col1 {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}

.proj-title {
  font-size: calc(20px + 4vw);
  margin: 1vh 0vh 1vh 0vh;
  /* font-size: 6rem; */
  padding: 0;
}

.proj-link {
  margin: 0;
  padding: 0;
  font-style: italic;
}

.proj-desc {
}

.page-link {
  margin: 0;
  font-style: italic;
}

.col2 {
}

.col2 img {
  height: auto;
  width: 45em;
}

.col2 img:hover {
  opacity: 80%;
}

.little-projects {
  display: flex;
  justify-content: space-between;
}

.little-header {
  text-align: center;
}

@media only screen and (max-width: 1500px) {
  .little-projects {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 20px;
  }
}

.card1,
.card2,
.card3 {
  text-align: center;
  width: calc(200px + 10%);
  padding: 30px;
  margin: 0px;
  background-color: var(--sky);
  color: var(--ocean);
}

.card1 {
  background-image: url('../../resources/dinosaurs.png');
}

.card2 {
  background-color: var(--border);
}

.card3 {
  background-color: var(--ocean);
}
