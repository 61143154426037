.foot {
  margin: 0;
  background-color: var(--ocean);
  color: var(--sky);
  padding-bottom: 2vh;
  padding-left: 10vw;
  padding-right: 10vw;
}

.foot a {
  text-decoration: underline;
  font-weight: 2;
  font-size: calc(8px + 1vh);
  text-align: center;
}

.foot p {
  font-size: calc(8px + 1vh);
  font-weight: 2;
  text-align: center;
}

.footcontainer {
  min-height: 5vh;
  border-top: 2px solid var(--sky);
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 2vw;
}

@media only screen and (max-width: 961px) {
  .footcontainer {
    display: flex;
    flex-direction: column;
  }
}
