.contactpage {
  min-height: 90vh;
  background-color: var(--ocean);
  padding-top: 10vh;
  padding-left: 10vw;
  padding-right: 10vw;
  color: var(--sky);
}

.contact-text {
  min-height: 50%;
}

.links {
  display: flex;
  padding-top: 100px;
  justify-content: space-around;
  align-items: center;
  font-size: calc(48px + 4vw);
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 961px) {
  .links {
    display: flex;
    flex-direction: column;
    padding: 10vw;
    margin: 0;
    gap: 5vw;
  }
}
