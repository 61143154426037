@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;600&display=swap');

body {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--ocean);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Colours */
:root {
  --ocean: #1e293b; /*Tailwind Slate 800*/
  --border: #0f172a; /*Tailwind Slate 900*/
  --sky: #fffbeb; /*Tailwind Amber 50*/
  --texthover: #a8a29e; /*Tailwind Stone 400*/
}

/* Utility Class */
.onScreen {
  opacity: 1;
  transition: all 1.5s;
  transform: translateX(0);
}

.flexcol {
  display: flex;
  flex-direction: column;
}

.centre {
  align-items: center;
}
