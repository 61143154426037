.whoami {
  margin: 0;
  min-height: 90vh;
  background-color: var(--ocean);
  padding-top: 10vh;
  padding-left: 10vw;
  padding-right: 10vw;
  color: var(--sky);
}

.inline {
  display: flex;
  justify-content: space-between;
}

.paragraph p {
  max-width: 100%;
  font-size: calc(2rem);
}

.portrait {
  padding: 2vh;
  display: inline;
  float: right;
  max-height: 50vh;
  padding-left: 5vw;
}

.caption {
  font-style: italic;
  text-align: center;
}

@media only screen and (max-width: 1280px) {
  .inline {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .portrait {
    height: auto;
    width: 20rem;
    padding: 0;
    object-fit: contain;
  }

  .paragraph {
  }

  .paragraph p {
    font-size: calc(1rem);
  }
}
