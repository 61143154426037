.App {
  margin: 0;
  padding: 0;
}

.landing {
  min-height: 100svh;
}

.line {
  border-bottom: 2px solid var(--sky);
}

.line h1 {
  font-size: calc(8px + 4vh);
}

h2 {
  font-size: calc(8px + 4vh);
}

p {
  word-spacing: 2px;
  font-size: calc(10px + 1vw);
  text-align: justify;
  font-weight: 1;
}

ul,
ol {
  font-size: calc(10px + 0.5vw);
  word-spacing: 2px;
  font-weight: 1;
}

li:not(:last-child) {
  margin-bottom: 3px;
}

a {
  color: var(--sky);
  text-decoration: none;
}

a:hover {
  color: var(--texthover);
}

.welcome {
  position: relative;
  min-height: 50svh;
  background-color: var(--sky);
  margin: 0;
  padding-left: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
}

.welcome h1 {
  color: var(--ocean);
  font-size: calc(12px + 6vw);
  margin: 0;
  animation: 1s ease-out 0s 1 slideInFromOcean;
}
.welcome p {
  margin: 0;
  font-size: calc(10px + 2vw);
  color: var(--ocean);
  opacity: 0;
  animation: 1s ease-out 1s 1 slideInFromOcean forwards;
}

.arrow {
  color: var(--sky);
  scale: 2;
  position: absolute;
  top: 45vh;
  right: 50vw;
  opacity: 0;
  animation: 1s ease-out 1.8s 1 slideInFromSky forwards;
}

.waves {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50svh;
  background-color: var(--ocean);
  overflow: hidden;
}
.waves:before,
.waves:after {
  content: '';
  position: absolute;
  left: 50%;
  min-width: 300vw;
  min-height: 300vw;
  background-color: var(--sky);
  animation: rotate linear infinite;
}
.waves:before {
  bottom: 15vh;
  border-radius: 45%;
  animation-duration: 10s;
}
.waves:after {
  bottom: 12vh;
  opacity: 0.5;
  border-radius: 47%;
  animation-duration: 10s;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

@keyframes slideInFromOcean {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromSky {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  50%,
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(0px);
  }
  10%,
  40% {
    transform: translateY(5px);
    scale: 1.2;
  }
  20% {
    transform: translateY(-5px);
  }
}

@keyframes buzz {
  50%,
  100% {
    rotate: 0deg;
    scale: 1;
  }
  0% {
    rotate: 6deg;
  }
  10%,
  40% {
    rotate: -6deg;
    scale: 1.3;
  }
  20% {
    rotate: 6deg;
    scale: 1.15;
  }
}

@keyframes pulse {
  50%,
  100% {
    rotate: 0deg;
    scale: 1;
  }
  0% {
    scale: 1.1;
  }
  10%,
  40% {
    scale: 1.2;
  }
  20% {
    scale: 1.1;
  }
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }
  50% {
    scale: 1.5;
    rotate: 360deg;
  }
  100% {
    rotate: 360deg;
  }
}
